<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ edit ? "Edit" : "Add" }} Popup</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="popup.title"
                    label="Name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.popup.title.$error">
                    Name is required
                  </span>
                  <span class="text-danger" v-if="this.errors.title">
                    {{ this.errors.title[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="popup.link_url"
                    label="Link URL"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.popup.link_url.$error">
                    Link is required
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="popup.type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    label="Popup Type"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  <span class="text-danger" v-if="$v.popup.type.$error">
                    Type is required
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="startDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="popup.start_date"
                        label="Starting Date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="date"
                      v-model="popup.start_date"
                      @input="startDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" v-if="popup.start_date">
                  <v-menu
                    v-model="endDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="popup.end_date"
                        label="Ending Date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="popup.start_date"
                      v-model="popup.end_date"
                      @input="endDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  v-if="popup.type == 'text' || popup.type == 'both'"
                >
                  Description
                  <ckeditor
                    v-model="popup.description"
                    :config="editorConfig"
                  ></ckeditor>
                  <span class="text-danger" v-if="$v.popup.description.$error">
                    Description is required
                  </span>
                  <span class="text-danger" v-if="this.errors.description">
                    {{ this.errors.description[0] }}
                  </span>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="popup.type == 'image' || popup.type == 'both'"
                >
                  <v-file-input
                    class="mb-3"
                    @change="previewImages()"
                    v-model="popup.image"
                    label="Popup Image"
                    accept="image/*"
                    small-chips
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    hide-details
                    outlined
                    dense
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  v-if="popup.type == 'image' || popup.type == 'both'"
                >
                  <v-img
                    class="w-100 max-h-300px"
                    contain
                    :src="previewImage"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="popup.is_active"
                    label="Status"
                    outlined
                    dense
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import PopupService from "@/service/cms/popup/PopupService";

const popupService = new PopupService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      startDate: false,
      endDate: false,
      edit: false,
      errors: [],
      previewImage: require("@/assets/images/no-image.png"),
      types: [
        {
          name: "Image",
          value: "image"
        },
        {
          name: "Text",
          value: "text"
        }
      ],
      popup: {
        title: "",
        description: "",
        link_url: "",
        start_date: "",
        end_date: "",
        image: null,
        type: "image",
        is_active: true
      },
      editorConfig: {
        toolbar: [["Bold", "Italic", "Underline", "BulletedList"]]
      }
    };
  },

  validations: {
    popup: {
      title: { required },
      description: { required: requiredIf(popup => popup.type === "text") },
      type: { required },
      link_url: { required }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.reset();
    },

    createPopup() {
      this.dialog = true;
      this.edit = false;
      this.reset();
    },

    editPopup(popup) {
      this.dialog = true;
      this.edit = true;
      this.reset();
      this.popup = popup;
      if (this.popup.image_path) {
        this.previewImage = this.popup.image_path.thumb;
      }
    },

    createOrUpdate() {
      this.$v.popup.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.create();
        } else {
          this.update();
        }
      }
    },

    reset() {
      this.previewImage = require("@/assets/images/no-image.png");
      this.$v.$reset();
      this.popup = {
        title: "",
        description: "",
        image: null,
        link_url: "",
        start_date: "",
        end_date: "",
        is_active: true
      };
    },
    create() {
      let formData = this.setFormData();
      popupService
        .store(formData)
        .then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Add popup successfully");
            this.close();
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
          }
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        });
    },
    update() {
      let formData = this.setFormData();
      popupService.update(this.popup.id, formData).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Update popup successfully");
          this.close();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    },
    previewImages() {
      if (this.popup.image != null) {
        this.previewImage = URL.createObjectURL(this.popup.image);
      } else {
        this.previewImage = require("@/assets/images/no-image.png");
      }
    },
    setFormData() {
      let formData = new FormData();
      for (let key in this.popup) {
        if (
          key === "image" &&
          this.popup[key] != null &&
          this.popup[key] != undefined
        ) {
          formData.append("image", this.popup[key]);
        } else {
          if (this.popup[key]) {
            formData.append(key, this.popup[key]);
          }
        }
      }
      return formData;
    }
  }
};
</script>

<style scoped></style>
