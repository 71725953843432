<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Popup</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Popup
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn class="btn btn-primary" @click="createPopup()" dark>
                    Add Popup
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-3 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Title"
                    v-model="search.title"
                    v-on:keyup.enter="searchPopup"
                    @input="search.title = $event !== null ? $event : ''"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    v-model="search.type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    label="Type"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                    v-model="startDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.start_date"
                        label="Starting Date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="date"
                      v-model="search.start_date"
                      @input="startDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                    v-model="endDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.end_date"
                        label="Ending Date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="search.end_date"
                      v-model="search.end_date"
                      @input="endDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    :items="status"
                    v-model="search.is_active"
                    v-on:keyup.enter="searchPopup"
                    @input="search.is_active = $event !== null ? $event : ''"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <button
                    class="w-15 btn btn-primary float-right"
                    @click="searchPopup"
                  >
                    Search
                  </button>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">
                      <strong>Title</strong>
                    </th>
                    <th class="px-3">
                      <strong>Date</strong>
                    </th>
                    <th class="px-3"><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>

                <template v-if="popups.length > 0">
                  <draggable
                    @change="sort"
                    @start="drag = true"
                    @end="drag = false"
                    tag="tbody"
                  >
                    <tr v-for="(item, index) in popups" :key="index">
                      <td class="px-2">
                        <a
                          @click="editPopup(item)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          <span class="ml-3">{{ item.title }}</span>
                        </a>
                      </td>
                      <td class="px-2">
                        <p class="ml-3">
                          <b>Start Date: </b> {{ item.start_date }}
                        </p>
                        <p class="ml-3">
                          <b>End Date: </b> {{ item.end_date }}
                        </p>
                      </td>
                      <td class="px-2">
                        <span
                          class="badge text-lg"
                          v-bind:class="{
                            'badge-success': item.is_active,
                            'badge-danger': !item.is_active
                          }"
                        >
                          {{ item.is_active ? "Active" : "In Active" }}
                        </span>
                      </td>

                      <td class="pr-0 px-2 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editPopup(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <v-icon color="blue darken-2"
                                      >fas fa-edit</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Edit Popup</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click.prevent="deletePopup(item.id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </draggable>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">
                      <h5>No Data Found</h5>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>

          <create-and-update
            ref="create-and-update"
            @refresh="getAllPopups"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "@/view/pages/cms/popup/CreateAndUpdate";
import PopupService from "@/service/cms/popup/PopupService";
import draggable from "vuedraggable";

const popup = new PopupService();
export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      drag: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      startDate: false,
      endDate: false,
      popups: [],
      page: null,
      search: {
        title: "",
        type: "",
        start_date: "",
        end_date: "",
        is_active: ""
      },
      status: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ],
      types: [
        {
          name: "Image",
          value: "image"
        },
        {
          name: "Text",
          value: "text"
        }
      ]
    };
  },
  mounted() {
    this.getAllPopups();
  },

  methods: {
    searchPopup() {
      this.getAllPopups();
    },

    createPopup() {
      this.$refs["create-and-update"].createPopup();
    },

    editPopup(popup) {
      this.$refs["create-and-update"].editPopup(popup);
    },

    deletePopup(id) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            popup.delete(id).then(response => {
              if (response.data.status == "OK") {
                this.$snotify.success("Delete Successfully");
                this.getAllPopups();
              } else {
                this.$snotify.error("Something Went Wrong");
              }
            });
          }
        }
      });
    },

    getAllPopups() {
      popup
        .paginate(this.search, this.page)
        .then(response => {
          this.popups = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    sort(popups) {
      popup.sort(popups).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Sorted Successfully");
          this.getAllNotices();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    }
  }
};
</script>

<style scoped></style>
